// Static pages
const e500 = () => import('@/components/pages/errors/500.vue')
const maintenance = () => import('@/components/pages/errors/maintenance.vue')
const contact = () => import('@/components/pages/others/Contact.vue')
const newsletter = () => import('@/components/pages/others/Newsletter.vue')
const polesWorldCulture = () => import('@/components/poles-world-culture/PolesWordCulture.vue')
const memory = () => import('@/components/games/memory/Memory.vue')

export const staticRoutes = [
  {
    path: '/',
    redirect: {
      path: '/en'
    }
  },
  {
    path: '/jazzahead2023',
    redirect: {
      path: '/en/jazzahead2023'
    }
  },
  {
    path: '/GreenhouseSilentDisco',
    redirect: {
      path: '/en/article/greenhouse-silent-disco'
    }
  },
  {
    path: '/:language/500',
    component: e500,
    meta: {
      type: 'e500'
    }
  },
  {
    path: '/:language/maintenance',
    component: maintenance,
    meta: {
      type: 'maintenance'
    }
  },
  {
    path: '/pl/kontakt',
    component: contact,
    meta: {
      type: 'contact'
    }
  },
  {
    path: '/en/contact',
    component: contact,
    meta: {
      type: 'contact'
    }
  },
  {
    path: '/ru/kontakt',
    component: contact,
    meta: {
      type: 'contact'
    }
  },
  {
    path: '/ua/kontakt',
    component: contact,
    meta: {
      type: 'contact'
    }
  },
  {
    path: '/:language/newsletter',
    component: newsletter,
    meta: {
      type: 'newsletter'
    }
  },
  {
    path: '/pl/sladami-polakow',
    component: polesWorldCulture,
    meta: {
      type: 'polesWorldCulture'
    }
  },
  {
    path: '/en/poles-far-and-wide',
    component: polesWorldCulture,
    meta: {
      type: 'polesWorldCulture'
    }
  },
  {
    path: '/ru/sledami-poljakov',
    component: polesWorldCulture,
    meta: {
      type: 'polesWorldCulture'
    }
  },
  {
    path: '/en/influence-mickiewicz',
    component: contact,
    beforeEnter() {
      window.location.href = "https://culture.pl/en/influence-mickiewicz";
    }
  },
  {
    path: '/pl/wplyw-mickiewicz',
    component: contact,
    beforeEnter() {
      window.location.href = "https://culture.pl/pl/wplyw-mickiewicz";
    }
  },
  {
    path: '/en/presidency/regeneration',
    component: contact,
    beforeEnter() {
      window.location.href = "https://culture.pl/static/pdf/Regeneration-pages.pdf";
    }
  },
  {
    path: '/en/presidency/lobby',
    component: contact,
    beforeEnter() {
      window.location.href = "https://culture.pl/static/pdf/02_Regeneration_The_Justus_Lipsius_Building_Lobby.pdf";
    }
  },
  {
    path: '/en/document/Le-Quart-dheure-polonais',
    component: contact,
    beforeEnter() {
      window.location.href = "https://culture.pl/static/pdf/Le-Quart-dheure-polonais-folder.pdf";
    }
  },
  {
    path: '/en/presidency/regeneration_strasburg',
    redirect: {
      path: '/'
    }
  },
  {
    path: '/en/presidency/floor-50',
    component: contact,
    beforeEnter() {
      window.location.href = "https://culture.pl/static/pdf/04_Regeneration_The_Justus_Lipsius_Building_Presidency_Rooms_Floor_50.pdf";
    }
  },
  {
    path: '/en/presidency/floor-50-the-presidency-room',
    component: contact,
    beforeEnter() {
      window.location.href = "https://culture.pl/static/pdf/03_Regeneration_The_Justus_Lipsius_Building_Presidency_Rooms_Floor_50.pdf";
    }
  },
  {
    path: '/en/presidency/the-presidency-rooms',
    component: contact,
    beforeEnter() {
      window.location.href = "https://culture.pl/static/pdf/01_Regeneration_The_Europa_Building-Presidency_Rooms.pdf";
    }
  },
  {
    path: '/en/presidency/temporary-art-exhibition',
    component: contact,
    beforeEnter() {
      window.location.href = "https://culture.pl/static/pdf/05_European_Parliament_Temporary_Art_Exhibition_Space.pdf";
    }
  },
  {
    path: '/pl/gry/memory',
    component: memory,
    meta: {
      type: 'games'
    },
    redirect: {
      path: '/en/games/memory'
    }
  },
  {
    path: '/en/games/memory',
    component: memory,
    meta: {
      type: 'games'
    }
  }
]
